export const CATEGORIES = [
  "Captain Tom 100",
  "London",
  "Travel",
  "Health & Fitness",
  "Food & Drink",
  "Books",
  "Film & TV",
  "Customisable",
  "Sports",
  "Arts & Culture",
  "Daily Life",
  "Music",
  "30 day challenges",
  "Theatre",
  "Get Creative",
  "Productivity",
  "At Home",
  "Wellbeing",
  "For Kids",
  "Health",
  "Fitness",
  "Mindfulness",
  "Yoga",
  "Workouts",
  "Exercise",
  "Walks",
  "Reading",
  "Football",
  "Extreme sports",
  "Basketball",
  "Cricket",
  "Tennis",
  "Film",
  "TV",
  "Gigs",
  "Global travel",
  "UK Travel",
  "Exploring",
  "Sightseeing",
  "Food",
  "Drink",
  "Beer",
  "Entertain Kids",
  "Eating out",
  "Golf",
  "Indoor Entertainment",
  "Gaming",
  "Green",
  "Christmas",
  "Charity"
]
