const awsConfig = {
  aws_app_analytics: 'enable',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'eu-west-1_K1L1Dr9i3',
  aws_user_pools_mfa_type: 'OFF',
  aws_user_pools_web_client_id: '39upoqrgro0kenlh0mglilijcm',
  aws_user_settings: 'enable',
};

export default awsConfig
