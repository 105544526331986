import React, {useEffect, useState} from 'react'
import config from '../../configs/config'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react'
import {CChartLine} from '@coreui/react-chartjs'
import {getStyle} from '@coreui/utils'
import {Col, Container, Row} from "react-bootstrap";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import Card from "../../components/Card/Card";
import {Auth} from "@aws-amplify/auth";
const Metrics = () => {
  const [userData, setUserData] = useState({userCounts: [], cognitoStats: []})
  const [userDataFilter, setUserDataFilter] = useState('All')
  const baseValue = 219
  const {SearchBar} = Search;

  const cognitoColumns = [
    {
      dataField: 'date',
      text: "Date",
      sort: true,
      // headerStyle: (column, colIndex) => {
      //   return {width: '300px'};
      // }
    },
    {dataField: 'count', text: "Count", sort: true},
    {dataField: 'total', text: "Total", sort: true}];
  const usersColumns = [
    {dataField: 'poolName', text: "Pool", sort: true},
    {dataField: 'count', text: "Count", sort: true}];

  useEffect(() => {
    loadUserData()
  }, [])
  const loadUserData = () => {
    Auth.currentSession({
      bypassCache: true,
    }).then((user) => {
      fetch(config.BASE_URL + '/usercount', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.getIdToken().getJwtToken(),
        },
      })
      .then((res) => res.json())
      .then((data) => {
        let cognitoStats = data.metrics.find(
            (x) => x.MetricName === 'UserSignups').data
        let processedCognitoStats = []
        let count = baseValue
        for (const [key, value] of Object.entries(cognitoStats)) {
          processedCognitoStats = processedCognitoStats.concat({
            date: key,
            count: value,
          })
        }
        processedCognitoStats = processedCognitoStats.sort(function (a, b) {
          return new Date(b.date) < new Date(a.date)
        })
        for (const i of processedCognitoStats) {
          count = count + i.count
          i.total = count
        }
        if(data && data.pools) {
          let today = new Date().toISOString().split('T')[0]
          let yesterdayCount = processedCognitoStats
          .find(x => x.date === new Date(Date.now() - 86400000)
          .toISOString().split('T')[0]);
          processedCognitoStats = processedCognitoStats.filter(x => x.date !== today).concat({
            date: today,
            total:data.pools[0].count
                + data.pools[1].count,
            count: data.pools[0].count
                + data.pools[1].count - (yesterdayCount ? yesterdayCount.total : 0)
          })
        }
        setUserData({
          userCounts: data.pools,
          cognitoStats: processedCognitoStats,
        })
      })
    })
  }

  const getUserDateHeaders = () => {
    let lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    let lastYear = new Date()
    lastYear.setFullYear(lastYear.getFullYear() - 1)
    switch (userDataFilter) {
      case 'All':
        return userData?.cognitoStats.map((x) => x.date)
      case 'Month':
        return userData?.cognitoStats.filter(
            (x) => new Date(x.date) > lastMonth).map((x) => x.date)
      case 'Year':
        return userData?.cognitoStats.filter(
            (x) => new Date(x.date) > lastYear).map((x) => x.date)
      default:
        return userData?.cognitoStats.map((x) => x.date)
    }
  }
  const getUserDateData = () => {
    let lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    let lastYear = new Date()
    lastYear.setFullYear(lastYear.getFullYear() - 1)
    switch (userDataFilter) {
      case 'All':
        return userData?.cognitoStats.map((x) => x.total + baseValue)
      case 'Month':
        return userData?.cognitoStats
        .filter((x) => new Date(x.date) > lastMonth)
        .map((x) => x.total + baseValue)
      case 'Year':
        return userData?.cognitoStats
        .filter((x) => new Date(x.date) > lastYear)
        .map((x) => x.total + baseValue)
      default:
        return userData?.cognitoStats.map((x) => x.total + baseValue)
    }
  }

  return (
      <div className="content">
        <Container fluid>
          <Row key={'key1'}>
            <Col md={12}>
              <Card
                  title=""
                  category=""
                  ctTableFullWidth={false}
                  ctTableResponsive={true}
                  content={
                    <ToolkitProvider
                        striped
                        hover
                        keyField="id"
                        data={userData.userCounts
                        && userData.userCounts.length > 0
                            ? userData.userCounts.concat({
                              poolName: 'Total',
                              count: userData.userCounts[0].count
                                  + userData.userCounts[1].count
                            }) : []}
                        columns={usersColumns}
                        // rowEvents={this.rowEvents}
                        wrapperClasses="table-responsive"
                        search
                    >
                      {
                        props => (
                            <div>
                              <h4 className="title">Users</h4>
                              <hr/>
                              <div className="tableStyle">
                                <BootstrapTable
                                    {...props.baseProps}
                                    // selectRow={this.rowEvents}
                                    // rowEvents={this.rowEvents}
                                />
                              </div>
                            </div>
                        )
                      }
                    </ToolkitProvider>
                  }
              />
            </Col>
          </Row>
          <Row key={'key2'}>
            <Col md={12}>
              <CCard className="mb-4">
                <CCardBody>
                  <CRow>
                    <CCol sm={5}>
                      <h4 id="traffic" className="card-title mb-0">
                        User Count
                      </h4>
                      <div className="small text-medium-emphasis">August 2020 -
                        Present
                      </div>
                    </CCol>
                    <CCol sm={7} className="d-none d-md-block">
                      <CButtonGroup className="float-end me-3">
                        {['All', 'Month', 'Year'].map((value) => (
                            <CButton
                                color="outline-secondary"
                                key={value}
                                onClick={() => setUserDataFilter(value)}
                                className="mx-0"
                                active={value === userDataFilter}
                            >
                              {value}
                            </CButton>
                        ))}
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                  <CChartLine
                      style={{height: '300px', marginTop: '40px'}}
                      data={{
                        labels: getUserDateHeaders(),
                        datasets: [
                          {
                            label: 'New users',
                            backgroundColor: '#b3b5c6',
                            borderColor: getStyle('--cui-info'),
                            pointHoverBackgroundColor: getStyle('--cui-info'),
                            borderWidth: 2,
                            data: getUserDateData(),
                            fill: true,
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              drawOnChartArea: false,
                            },
                          },
                          y: {
                            ticks: {
                              beginAtZero: true,
                              maxTicksLimit: 5,
                              stepSize: Math.ceil(250 / 5),
                              max: 250,
                            },
                          },
                        },
                        elements: {
                          line: {
                            tension: 0.4,
                          },
                          point: {
                            radius: 0,
                            hitRadius: 10,
                            hoverRadius: 4,
                            hoverBorderWidth: 3,
                          },
                        },
                      }}
                  />
                </CCardBody>
              </CCard>
            </Col>
          </Row>
          <Row key='key3'>
            <Col md={12}>
              <ToolkitProvider
                  striped
                  hover
                  keyField="id"
                  data={userData.cognitoStats}
                  columns={cognitoColumns}
                  wrapperClasses="table-responsive"
                  search
              >
                {
                  props => (
                      <div>
                        <h4 className="title">Daily New
                          Users&nbsp;&nbsp;&nbsp;&nbsp;
                          <SearchBar {...props.searchProps} /></h4>
                        <hr/>
                        <div className="tableStyle">
                          <BootstrapTable
                              {...props.baseProps}
                              // selectRow={this.rowEvents}
                              defaultSorted={[{
                                dataField: 'date', // if dataField is not match to any column you defined, it will be ignored.
                                order: 'desc' // desc or asc
                              }]}
                          />
                        </div>
                      </div>
                  )
                }
              </ToolkitProvider>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default Metrics
