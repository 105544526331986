import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import ItemTypes from './ItemTypes'
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/CustomButton/CustomButton.jsx";

const style = {
  border: '1px solid gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}
const Card = ({id, text, index, moveCard, deleteCard}) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{isDragging}, drag] = useDrag({
    item: {type: ItemTypes.CARD, id, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const remove = <Tooltip id="remove_tooltip">Remove</Tooltip>;
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
      <div ref={ref} style={{...style, opacity}}>
        {/*<Button size="sm" onClick={() => deleteCard(id)}>X</Button>&nbsp;&nbsp;&nbsp;*/}
        {text}&nbsp;&nbsp;&nbsp;
        <OverlayTrigger placement="top" overlay={remove}>
          <Button bsStyle="danger" onClick={() => deleteCard(id)} simple
                  type="button" bsSize="sm">
            {/*<i className="fa fa-times" />*/}
            <FontAwesomeIcon icon={faTimes}/>
          </Button>
        </OverlayTrigger>
      </div>
  )
}
export default Card
