import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Col, Container, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {defaultFAQ} from "./defaultValues";
import ReactMarkdown from 'react-markdown'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {notification} from "antd";
import {Auth} from "aws-amplify";
import config from "../../configs/config";

const AppData = () => {
  const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm();
  const onSubmit = data => {
    console.log(data)
    Auth.currentSession({
      bypassCache: true
    }).then(user => {
      let url = config.BASE_URL + '/appdata/FAQ'
      fetch(url, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.getIdToken().getJwtToken()
        },
        body: JSON.stringify({markdown: data.markdownfaq}),
      }).then(res => {
            if (!res.ok) {
              notification.open({
                type: 'error',
                message: 'Something went wrong',
                description: 'status: ' + res.status,
                duration: 10
              });
              res.json()
              .then(response =>
                  notification.open({
                    type: 'error',
                    message: 'Something went wrong',
                    description: 'error' + response,
                    duration: 10
                  }))
              .catch(err => alert(err))
            }
            res.json()
            .then(response => {
              notification.open({
                type: 'success',
                message: 'FAQ edited successfully',
                description: 'FAQ will be live as soon as cache refreshes',
                duration: 10
              });
              console.log(response);
            })
            .catch(err => alert(err))
          }
      )
      .catch(error => alert(error));

    })
    .catch(err => {
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: err,
        duration: 10
      });
    });

  };
  useEffect(() => {
    fetch(config.BASE_URL + '/appdata/FAQ', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
          if (res.status !== 200) {
            notification.open({
              type: 'error',
              message: 'Something went wrong',
              description: 'status: ' + res.status,
              duration: 10
            });
            return;
          }
          res.json()
          .then(response => {
            if(response && response.markdown) {
              setValue("markdownfaq", response.markdown);
            }
          })
          .catch(err => {
            console.log(err)
            notification.open({
              type: 'error',
              message: 'Something went wrong',
              description: err,
              duration: 10
            });
          })
        }
    )
    .catch(err => {
      console.log(err)
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: err,
        duration: 10
      });
    })  },[])

  let livefaq = watch("markdownfaq"); // watch input value by passing the name of it
  return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                  title="FAQ"
                  category=""
                  ctTableFullWidth={false}
                  ctTableResponsive={true}
                  content={
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/* register your input into the hook by invoking the "register" function */}
                      {errors.markdownfaq && errors.markdownfaq.type === 'required' &&
                      <span>This field is required</span>}
                      <Form.Control
                          rows={20}
                          as="textarea"
                          placeholder="Markdown"
                          name="Markdown"
                          // defaultValue={defaultFAQ}
                          {...register("markdownfaq", {required: true})}
                      />
                      <br />
                      <Button type="submit">Submit FAQ</Button>

                    </form>}/>
            </Col>
            <Col md={6}>
              <Card
                  title="FAQ Preview"
                  category=""
                  ctTableFullWidth={false}
                  ctTableResponsive={true}
                  content={
                    <div>
                      <ReactMarkdown>{livefaq}</ReactMarkdown>
                    </div>
                  }/>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default AppData;
