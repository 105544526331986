/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";

import {Card} from "../../components/Card/Card";
import {FormInputs} from "../../components/FormInputs/FormInputs";
import {Auth} from "aws-amplify";
import {notification} from "antd";
import config from "../../configs/config";
import Form from "react-bootstrap/Form";
import ReactJson from "react-json-view";

const User = (props) => {
  const [userData, setUserData] = useState({})
  const userToLookUp = React.useRef();
  useEffect(() => {
    console.log(props.match.params.id)
    if (props.match.params.id && props.match.params.id.length > 0) {
      loadUser(props.match.params.id);
    }
  }, [props.match.params.id]);

  const loadUser = (username) => {
    Auth.currentSession({
      bypassCache: true
    })
    .then(user => {
      return fetch(
          config.BASE_URL + '/user/' + username,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + user.getIdToken().getJwtToken()
            },
          })
    })
    .then(res => res.json())
    .then((data) => {
      console.log(data)
      setUserData(data)
    })
    .catch(err => {
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: 'User not found',
        duration: 10
      });
      console.log(err)
    });
  }

  return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                  title="User Lookup"
                  content={
                    <Form>
                      <Form.Group as={Col} md="10"
                                  controlId="descriptionControl">
                        <Form.Label>UserId Search</Form.Label>
                        <Form.Control
                            type="textarea"
                            placeholder="UserID"
                            ref={userToLookUp}
                            name="UserId"
                            maxLength="96"
                        />
                      </Form.Group>
                      <Button bsStyle="info" pullRight fill
                              onClick={() => loadUser(
                                  userToLookUp.current.value)}>
                        Get profile
                      </Button>
                      <div className="clearfix"/>
                    </Form>
                  }
              />
            </Col>
          </Row>
          {userData && userData.profile && (<Row>
            <Col md={12}>
              <Card
                  title="Profile"
                  content={
                    <form>
                      <FormInputs
                          ncols={["col-md-6", "col-md-4"]}
                          properties={[
                            {
                              label: "Username",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Username",
                              editable:false,
                              defaultValue: userData.profile?.UserId,
                            },
                            {
                              label: "Bio",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Bio",
                              editable:false,
                              defaultValue: userData.profile?.Bio
                            }
                          ]}
                      />
                      <FormInputs
                          ncols={["col-md-6", "col-md-6"]}
                          properties={[
                            {
                              label: "First name",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "First name",
                              editable:false,
                              defaultValue: userData.profile?.given_name
                            },
                            {
                              label: "Last name",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Last name",
                              editable:false,
                              defaultValue: userData.profile?.family_name
                            }
                          ]}
                      />
                      <div className="clearfix"/>
                    </form>
                  }
              />
            </Col>
          </Row>)}
          {userData && userData.firebaseToken && (<Row>
            <Col md={12}>
              <Card
                  title="Firebase Token"
                  content={
                    <Form>
                      <Form.Group as={Col} md="10"
                                  controlId="descriptionControl">
                        <Form.Label>Firebase Id</Form.Label>
                        <Form.Control
                            type="textarea"
                            placeholder="UserID"
                            editable={false}
                            name="Token"
                            value={userData.firebaseToken}
                        />
                      </Form.Group>
                    </Form>
                  }
              />
            </Col>
          </Row>)}
          {userData && userData.subscriptions && (<Row>
            <Col md={12}>
              <Card
                  title="Subscriptions"
                  content={
                    <ReactJson src={userData?.subscriptions}/>
                  }
              />
            </Col>
          </Row>)}
          {userData && userData.myChallenges && (<Row>
            <Col md={12}>
              <Card
                  title="Enter As You Go"
                  content={
                    <ReactJson src={userData?.myChallenges}/>
                  }
              />
            </Col>
          </Row>)}
          {userData && userData.myList && (<Row>
            <Col md={12}>
              <Card
                  title="Favourites List"
                  content={
                    <ReactJson src={userData?.myList}/>
                  }
              />
            </Col>
          </Row>)}
          {userData && userData.friends && (<Row>
            <Col md={12}>
              <Card
                  title="Friends List"
                  content={
                    <ReactJson src={userData?.friends}/>
                  }
              />
            </Col>
          </Row>)}
        </Container>
      </div>
  );
}

export default User;
