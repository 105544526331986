import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./vcstyle.css"
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import Card from "../../components/Card/Card.jsx"
import config from "../../configs/config"
import {Auth} from "aws-amplify";
import {notification} from "antd";

const challengeColumns = [
  {
    dataField: 'name',
    text: "Name",
    sort: true,
    headerStyle: (column, colIndex) => {
      return {width: '300px'};
    }
  },
  {dataField: 'count', text: "Count", sort: true},
  {dataField: 'active', text: "Active", sort: true},
  {dataField: 'complete', text: "Ever Completed", sort: true},
  {dataField: 'updated', text: "Updated this week", sort: true}];
const {SearchBar} = Search;

class ViewSubscriptions extends Component {
  state = {
    tdSubscriptions: [],
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.setState({...this.state, redirectChallenge: row.id});
      this.setRedirect();
      console.log(row.id)
    }
  };

  componentDidMount() {
  Auth.currentSession({
      bypassCache: true
    })
    .then(user => {
      fetch(
          config.BASE_URL + '/subscription',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + user.getIdToken().getJwtToken()
            },
          })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          tdSubscriptions: data
        })

      })
      .catch(console.log)
    })
    .catch(err => {
      notification.open({
        type: 'error',
        message: 'Not logged in',
        description: 'Redirecting to login page',
        duration: 10
      });
      console.log(err)
    });
  }

  render() {
    return (

        <div className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card
                    title=""
                    category=""
                    ctTableFullWidth={false}
                    ctTableResponsive={true}
                    content={
                      <ToolkitProvider
                          striped
                          hover
                          keyField="id"
                          data={this.state.tdSubscriptions}
                          columns={challengeColumns}
                          wrapperClasses="table-responsive"
                          search
                      >
                        {
                          props => (
                              <div>
                                <h4 className="title">Subscriptions&nbsp;&nbsp;&nbsp;&nbsp;
                                  <SearchBar {...props.searchProps} /></h4>
                                <hr/>
                                <div className="tableStyle">
                                  <BootstrapTable
                                      {...props.baseProps}
                                      // selectRow={this.rowEvents}
                                      defaultSorted={[{
                                        dataField: 'count', // if dataField is not match to any column you defined, it will be ignored.
                                        order: 'desc' // desc or asc
                                      }]}
                                  />
                                </div>
                              </div>
                          )
                        }
                      </ToolkitProvider>
                    }
                />
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

export default ViewSubscriptions;
