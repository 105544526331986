import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./vcstyle.css"
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {Redirect} from 'react-router-dom'
import Card from "../../components/Card/Card.jsx"
import config from "../../configs/config"
import {Auth} from "aws-amplify";
import {notification} from "antd";

const challengeColumns = [
  {
    dataField: 'name',
    text: "Name",
    sort: true,
    headerStyle: (column, colIndex) => {
      return {width: '300px'};
    }
  },
  {dataField: 'description', text: "Description", sort: true},
  {dataField: 'type', text: "Type", sort: true},
  {dataField: 'categories', text: "Categories", sort: true},
  {dataField: 'active', text: "Active", sort: true},
  {dataField: 'liveAt', text: "Live At", sort: true},
  {dataField: 'updatedAt', text: "Updated At", sort: true},
  {dataField: 'createdAt', text: "Created At", sort: true},
  {dataField: 'id', text: "ID", sort: true}];
const {SearchBar} = Search;

class ViewChallenges extends Component {
  state = {
    tdChallenges: [],
    redirect: false,
    redirectChallenge: "",
    redirectHome: false,
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.setState({...this.state, redirectChallenge: row.id});
      this.setRedirect();
      console.log(row.id)
    }
  };
  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  setRedirectHome = () => {
    this.setState({
      redirectHome: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect
          to={'/challenges/create/' + this.state.redirectChallenge}/>
    }
  };
  renderRedirectHome = () => {
    if (this.state.redirectHome) {
      return <Redirect to={'/login'}/>
    }
  }
  componentDidMount() {
    Auth.currentSession({
      bypassCache: true
    }).then(() => {
      fetch(
          config.BASE_URL + '/challenge')
      .then(res => res.json())
      .then((data) => {
        this.setState({
          tdChallenges: data.sort(function (a, b) {
            return Date.parse(b.UpdatedAt) - Date.parse(a.UpdatedAt)
          }).map(c => this.createChallengeData(c))
        })
      })
      .catch(console.log)

    })
    .catch(err => {
      notification.open({
        type: 'error',
        message: 'Not logged in',
        description: 'Redirecting to login page',
        duration: 10
      });
      this.setRedirectHome(true);
    });
  }

  createChallengeData(challenge) {
    return {
      id: challenge.ChallengeId,
      name: challenge.Name,
      description: challenge.Description,
      type: challenge.ChallengeType,
      categories: challenge.Categories.toString(),
      active: challenge.IsActive,
      liveAt: challenge.LiveAt,
      updatedAt: challenge.UpdatedAt,
      createdAt: challenge.CreatedAt
    }
  }

  render() {
    return (

        <div className="content">
          <div>
            {this.renderRedirect()}
            {this.renderRedirectHome()}
          </div>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card
                    title=""
                    category=""
                    ctTableFullWidth={false}
                    ctTableResponsive={true}
                    content={
                      <ToolkitProvider
                          striped
                          hover
                          keyField="id"
                          data={this.state.tdChallenges}
                          columns={challengeColumns}
                          rowEvents={this.rowEvents}
                          wrapperClasses="table-responsive"
                          search
                      >
                        {
                          props => (
                              <div>
                                <h4 className="title">Challenges</h4>
                                <h5 className="title">Active: {this.state.tdChallenges.filter(x => x.active).length}    Total: {this.state.tdChallenges.length}</h5>
                                <br />
                                  <SearchBar {...props.searchProps} />
                                <hr/>
                                <div className="tableStyle">
                                  <BootstrapTable
                                      {...props.baseProps}
                                      // selectRow={this.rowEvents}
                                      rowEvents={this.rowEvents}
                                  />
                                </div>
                              </div>
                          )
                        }
                      </ToolkitProvider>
                    }
                />
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

export default ViewChallenges;
